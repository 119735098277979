import React from "react";
import "../styles/Home.css";
import logo from "../assets/MainLogo.png";

const Home = () => {
  return (
    <div className="home-container">
      <div className="hero-section">
        <img src={logo} alt="Glendale Analytics Logo" className="logo-image" />
        <h1>Welcome to Glendale Analytics, LLC</h1>
        <p>
          Expert analytical support and testimony to help you resolve complex
          legal disputes.
        </p>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </div>
      <section className="about-section">
        <h2>About Us</h2>
        <p>
          Founded in December 2023, Glendale Analytics is dedicated to providing
          clear, precise analytical support in complex legal cases.
        </p>
      </section>
      <section className="services-section">
        <h2>Our Expertise</h2>
        <ul>
          <li>Labor & Employment Law</li>
          <li>PAGA</li>
          <li>Class Actions</li>
          <li>Personal Injury</li>
          <li>Special Situations</li>
        </ul>
      </section>
    </div>
  );
};

export default Home;
