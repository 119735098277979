import React from "react";
import "../styles/About.css";

const About = () => {
  return (
    <div className="about-container">
      <h1>About Glendale Analytics</h1>
      <p>
        Glendale Analytics, LLC was founded in December 2023 with a mission to
        provide expert data analysis and dispute resolution support for complex
        legal cases. Our expertise spans a wide array of legal matters,
        including labor and employment law, PAGA, class actions, personal
        injury, and special situations.
      </p>
      <p>
        Our team is committed to delivering precise and actionable insights to
        support your legal strategies.
      </p>
    </div>
  );
};

export default About;
