// src/components/Navbar.js

import React from "react";
import { Nav, Navbar, NavLink, NavbarBrand } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/Header.css"; // Assuming Header.css is where your Navbar styles are
import logo from "../assets/Favicon.svg"; // Updated logo import
import wordLogo from "../assets/WhiteWordmark.svg"; // Updated logo import

function NavigationBar() {
  return (
    <Navbar
      className="navbar"
      collapseOnSelect
      expand="sm"
      bg="dark"
      variant="dark"
    >
      <NavbarBrand className="logo">
        <img
          src={logo}
          alt="Glendale Analytics Logo"
          style={{ height: "60px" }} // Adjust the size and spacing
        />
        <img
          src={wordLogo}
          alt="Glendale Analytics"
          style={{ height: "40px", marginRight: "0px", marginLeft: "10px" }} // Adjust the size and spacing
        />
      </NavbarBrand>
      <Navbar.Toggle
        aria-controls="navbarScroll"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
      />
      <Navbar.Collapse className="navOptions" id="navbarScroll">
        <Nav className="ml-auto">
          <NavLink eventKey="1" as={Link} to="/">
            Home
          </NavLink>
          <NavLink eventKey="2" as={Link} to="/about">
            About
          </NavLink>
          <NavLink eventKey="3" as={Link} to="/services">
            Services
          </NavLink>
          <NavLink eventKey="5" as={Link} to="/contact">
            Contact
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
