import React from "react";
import "../styles/Services.css";

const Services = () => {
  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <ul>
        <li>
          <strong>Labor & Employment Law:</strong> Expert support on wage
          disputes, wrongful termination, and other employment-related legal
          matters.
        </li>
        <li>
          <strong>PAGA:</strong> Analytical insights for Private Attorneys
          General Act cases, including penalties and damages estimations.
        </li>
        <li>
          <strong>Class Actions:</strong> Data analysis to identify trends,
          support claims, and estimate potential liabilities in large-scale
          actions.
        </li>
        <li>
          <strong>Personal Injury:</strong> Data-driven reports to assess
          damages, validate claims, and support case strategies.
        </li>
        <li>
          <strong>Special Situations:</strong> Tailored analytical support for
          unique, complex legal disputes that require custom solutions.
        </li>
      </ul>
    </div>
  );
};

export default Services;
