import React, { useState, useRef } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "../styles/Contact.css"; // Import the CSS file

export default function Contact() {
  const form = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (form.current.checkValidity()) {
      emailjs
        .sendForm("service_gqhzttj", "template_25pleyd", form.current, {
          publicKey: "GZEHjue1puJ0WzGOl",
        })
        .then(
          (response) => {
            console.log("SUCCESS!", response.text);
            setShowAlert(true);
            setAlertVariant("success");
            setAlertMessage("Email sent successfully!");
            form.current.reset();
          },
          (error) => {
            console.log("FAILED...", error.text);
            setShowAlert(true);
            setAlertVariant("danger");
            setAlertMessage("Failed to send email.");
          }
        );
    } else {
      setShowAlert(true);
      setAlertVariant("danger");
      setAlertMessage("Please fill in all fields.");
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Form ref={form} onSubmit={sendEmail} className="contact-form">
        <Form.Group controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="user_name"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide your name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="user_email"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formBasicMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your message"
            name="message"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a message.
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit">
          Send
        </Button>

        {showAlert && (
          <Alert
            variant={alertVariant}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            {alertMessage}
          </Alert>
        )}
      </Form>
    </Container>
  );
}
